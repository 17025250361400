<template>
<div :class="labelPosition === 'left' ? 'flex justify-between items-center' : ''">
    <div class="flex mt-2" :class="labelPosition === 'left' ? 'w-2/12' : ''">
        <div
            class="text-md text-gray-600 dark:text-white/75"
            :class="nowrap ? 'whitespace-nowrap' : ''">
            {{ label }}
        </div>
        <n-popover
            v-if="dataHelp"
            trigger="hover"
            placement="top"
            class="max-w-[500px] rounded-md bg-main">
            <template #trigger>
                <n-icon class="text-main cursor-pointer text-xl mx-2">
                    <QuestionCircle20Filled />
                </n-icon>
            </template>
            <span class="text-xs" v-html="dataHelp"></span>
        </n-popover>
    </div>
    <div :class="labelPosition === 'left' ? 'w-10/12' : 'w-full mt-2'">
        <n-select
            clearable
            size="medium"
            class="rounded-md"
            :multiple="multiple"
            :filterable="filterable"
            :options="options"
            :placeholder="`- ${t('select')} -`"
            :render-label="renderLabel"
            :loading="loading"
            :disabled="disabled || loading"
            :value="value !== -1 ? value : undefined"
            @update:value="$emit('update:value', $event)"/>
    </div>
    <slot name="messages"></slot>
  </div>
</template>

<script>
// vue
import { h, ref, computed } from 'vue';

// store
import { useRefs } from '@/store/refs';

// components
import RbCoin from '@components/rb-coin';
import GridNav from '@components/base/grid-nav.vue';

// i18n
import { useI18n } from 'vue-i18n';

// icons
import { Warning24Filled, QuestionCircle20Filled } from '@vicons/fluent';

// naive-ui
import {
    NCard,
    NIcon,
    NAlert,
    NInput,
    NSelect,
    NDivider,
    NPopover,
    NCheckbox,
    useMessage } from 'naive-ui';

export default {
    name: 'rb-coin-list',
    emits: ['update:value'],
    props: {
        label: {
            type: String,
            required: true,
        },
        labelPosition: {
            type: String,
            default: 'top',
            validator: prop => ['right', 'top'].includes(prop),
        },
        dataHelp: {
            type: String,
        },
        filterable: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array,
            required: true,
        },
        loading: {
            type: Boolean,
        },
        value: {
            type: [String, Number],
            required: true,
            default: null,
        },
        multiple: {
            type: Boolean,
        },
        disabled: {
            type: Boolean,
        },
        nowrap: {
            type: Boolean,
            default: true,
        },
    },
    components: {
        NIcon,
        NSelect,
        NPopover,
        QuestionCircle20Filled,
    },
    setup() {
        // store
        const refs = useRefs();
        
        // i18n
        const { t } = useI18n();

        const currency = (icon) => refs.currencies.find(el => el.id === icon);

        const renderLabel = option => {
            return h(
                'div',
                {
                    class: 'flex items-center py-1',
                },
                [
                    h(RbCoin, {
                        class: 'w-4',
                        coin: currency(option.value),
                    }),
                    h(
                        'div',
                        {
                            class: 'px-3 text-xs',
                        }, option.label,
                    ),
                ],
            );
        };

        return {
            t,
            renderLabel,
        };
    },
};
</script>