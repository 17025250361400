<template>
<n-alert
    v-if="tools.localization['volatility_analyzer_info']"
    class="rounded-md mx-3" type="warning" size="large">
    <div
        class="notes text-[10px]"
        v-html="tools.localization['volatility_analyzer_info']">
    </div>
</n-alert>

<n-tabs
    class="mt-4"
    type="card"
    size="medium"
    v-model:value="currentTab">
    <n-tab-pane
        v-for="(tab, i) in tabs"
        :key="i"
        :name="tab.name"
        :tab="tab.title">
        <section class="px-3">
            <component
                :is="tab.component"
                @chacngeTab="currentTab = $event">
            </component>
        </section>
    </n-tab-pane>
</n-tabs>
</template>

<script>
// general
import general from './general';

// icons
import { Warning24Filled } from '@vicons/fluent';

// UI
import {
    NTabs,
    NIcon,
    NAlert,
    NTabPane } from 'naive-ui';

export default {
    name: 'tools-analyzer-desktop',
    components: {
        NTabs,
        NIcon,
        NAlert,
        NTabPane,
        Warning24Filled,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>