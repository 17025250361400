import Api from './index';

export default class Volatility {

    static async getRefs() {
        return await Api.getData({
            action: 'volatility.getRefs',
        });
    };

    static async get(payload) {
        return await Api.getData({
            action: 'volatility.get',
            payload,
        });
    };
}