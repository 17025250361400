<template>
<div v-if="init">
    <n-card
        size="small"
        :bordered="false"
        class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-lg"
        :segmented="{
          content: true,
        }">
        <div class="flex mt-4">
            <rb-select
                class="w-6/12 pr-2"
                label-position="left"
                :label="dataForm.exchange.title"
                :help="dataForm.exchange.dataHelp"
                :options="exchangeOptions"
                :status="dataForm.exchange.status === 'error' ? 'error' : undefined"
                :msg="dataForm.exchange.msg"
                v-model:value="dataForm.exchange.value"
                @update:value="dataForm.exchange.status = null, dataForm.exchange.msg = null" />

            <rb-currencies
                v-if="dataForm.market.show"
                class="w-6/12 pl-2"
                label-position="left"
                :label="dataForm.market.title"
                :help="dataForm.market.dataHelp"
                :options="currencyOptions"
                :status="dataForm.market.status === 'error' ? 'error' : undefined"
                :msg="dataForm.market.msg"
                :defaultValue="-1"
                v-model:value="dataForm.market.value"
                @update:value="dataForm.market.status = null, dataForm.market.msg = null" />
        </div>

        <template v-if="dataForm.use_bw_list.show">
            <n-divider />
            <section class="flex items-start">
                <rb-switch
                    largeHelp
                    class="w-3/12 pr-2"
                    :label="dataForm.use_bw_list.title"
                    :help="dataForm.use_bw_list.dataHelp"
                    v-model:value="dataForm.use_bw_list.value" />
                
                <template v-if="dataForm.use_bw_list.value">
                    <rb-switch
                        class="px-2"
                        :label="tools.localization['tools_volatility_analyzer_set_white_list']"
                        :value="dataForm.is_white_list"
                        @update:value="dataForm.is_white_list = $event"/>

                    <rb-switch
                        class="px-2"
                        :label="tools.localization['tools_volatility_analyzer_set_black_list']"
                        :value="!dataForm.is_white_list"
                        @update:value="dataForm.is_white_list = !$event"/>

                    <rb-select
                        largeHelp
                        class="pl-2 flex-grow"
                        :class="dataForm.coin_list_strategy != -1 && dataForm.coin_list_strategy != 'local' ? 'pr-2' : ''"
                        :label="tools.localization['tools/va/settings/coin_list/select_strategy']"
                        :options="coinListStrategiesOptions"
                        :help="tools.help['coin_list_strategy']"
                        v-model:value="dataForm.coin_list_strategy" />

                    <rb-select
                        v-if="dataForm.coin_list_strategy != -1 && dataForm.coin_list_strategy != 'local'"
                        largeHelp
                        clearable
                        class="pl-2 flex-grow"
                        :label="tools.localization['tools/va/settings/coin_list/shared_coin_list']"
                        :options="sharedCoinListItems"
                        :help="tools.help['shared_coin_list']"
                        v-model:value="dataForm.shared_coin_list" />
                </template>
            </section>
            <n-divider />
            
            <section v-if="dataForm.use_bw_list.value">
                <n-divider />
                <!-- coin list selector -->
                <div v-if="canShowSharedCoinList" class="flex flex-col items-end">
                    <rb-coin-list
                        multiple
                        disabled
                        class="w-full"
                        :label="tools.localization['tools/va/settings/coin_list/shared_list_coins']"
                        :dataHelp="tools.help['shared_list_coins']"
                        :value="sharedCoinList"
                        :options="sharedCoinListRef" />
                    <!-- @click.prevent="router.push({name: 'tools.va', query: { tab: 'rbpsCoinList' }})" -->
                    <n-button
                        strong
                        class="text-white/90 rounded-md mt-4"
                        :color="gl.mainColor">
                        {{ tools.localization['tools/va/settings/coin_list/create_update_coin_list'] }}
                    </n-button>
                    <n-divider />
                </div>
                <div v-if="dataForm.coin_list_strategy != 'shared' && dataForm.coin_list_strategy != -1">
                    <rb-coin-list
                        multiple
                        :label="tools.localization['tools_volatility_analyzer_black_white_lists_currencies_list_f']"
                        v-model:value="dataForm.bw_list"
                        :options="symbolsForMarketRefsOptions" />
                    <!-- @click.prevent="router.push({name: 'tools.va', query: { tab: 'rbpsCoinList' }})" -->
                    <n-divider />
                </div>
            </section>
                
            <template v-if="marketSelected">
                <div class="flex mt-4">
                    <div class="flex justify-between w-3/12 pr-2">
                        <rb-input
                            class="w-6/12"
                            label-position="left"
                            :label="dataForm.period_value.title"
                            :help="dataForm.period_value.dataHelp"
                            :status="dataForm.period_value.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.period_value.msg"
                            v-model:value="dataForm.period_value.value"
                            @update:value="dataForm.period_value.status = null, dataForm.period_value.msg = null" />

                        <rb-select
                            class="w-6/12 pl-2"
                            :options="periodUnitsOptions"
                            v-model:value="dataForm.period_unit" />
                    </div>

                    <div class="flex justify-between w-3/12 px-2">
                        <rb-input
                            class="w-6/12"
                            label-position="left"
                            :label="dataForm.interval_value.title"
                            :help="dataForm.interval_value.dataHelp"
                            :status="dataForm.interval_value.status === 'error' ? 'error' : undefined"
                            :msg="dataForm.interval_value.msg"
                            v-model:value="dataForm.interval_value.value"
                            @update:value="dataForm.interval_value.status = null, dataForm.interval_value.msg = null" />

                        <rb-select
                            class="w-6/12 pl-2"
                            :options="intervalUnitsOptions"
                            v-model:value="dataForm.interval_unit" />
                    </div>
                </div>
                <n-divider />
            </template>
            
            <template v-if="marketSelected">
                <div class="flex mt-4">
                    <rb-input
                        class="w-3/12 pr-2"
                        :label="dataForm.rate_change.title"
                        :help="dataForm.rate_change.dataHelp"
                        :status="dataForm.rate_change.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.rate_change.msg"
                        :placeholder="dataForm.rate_change.placeholder"
                        v-model:value="dataForm.rate_change.value"
                        @update:value="dataForm.rate_change.status = null, dataForm.rate_change.msg = null" />
                    <rb-input
                        class="w-3/12 px-2"
                        :label="dataForm.min_rate_changes_count.title"
                        :help="dataForm.min_rate_changes_count.dataHelp"
                        :status="dataForm.min_rate_changes_count.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.min_rate_changes_count.msg"
                        :placeholder="dataForm.min_rate_changes_count.placeholder"
                        v-model:value="dataForm.min_rate_changes_count.value"
                        @update:value="dataForm.min_rate_changes_count.status = null, dataForm.min_rate_changes_count.msg = null" />
                    <rb-input
                        class="w-3/12 px-2"
                        :label="dataForm.min_trade_volume.title"
                        :help="dataForm.min_trade_volume.dataHelp"
                        :status="dataForm.min_trade_volume.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.min_trade_volume.msg"
                        :placeholder="dataForm.min_trade_volume.placeholder"
                        v-model:value="dataForm.min_trade_volume.value"
                        @update:value="dataForm.min_trade_volume.status = null, dataForm.min_trade_volume.msg = null" />
                    <rb-input
                        class="w-3/12 pl-2"
                        :label="dataForm.min_price.title"
                        :help="dataForm.min_price.dataHelp"
                        :status="dataForm.min_price.status === 'error' ? 'error' : undefined"
                        :msg="dataForm.min_price.msg"
                        :placeholder="dataForm.min_price.placeholder"
                        v-model:value="dataForm.min_price.value"
                        @update:value="dataForm.min_price.status = null, dataForm.min_price.msg = null" />
                </div>
                <n-divider />
            </template>

            <template v-if="marketSelected">
                <section>
                    <div class="flex mb-4">
                        <div class="text-xs text-gray-600 dark:text-white/75 whitespace-nowrap">
                            {{ tools.localization['tools_volatility_analyzer_pump_dump_filters_title'] }}
                        </div>
                        <n-popover
                            scrollable
                            trigger="hover"
                            class="max-w-[200px] max-h-[200px] rounded-md bg-main"
                            placement="top">
                                <template #trigger>
                                    <n-icon class="text-main cursor-pointer text-xl mx-2">
                                        <QuestionCircle20Filled />
                                    </n-icon>
                                </template>
                            <span class="text-xs text-white" v-html="tools.help['switch_pd_filters']"></span>
                        </n-popover>
                    </div>
                    
                    <section class="flex flex-wrap items-stretch">
                        <div
                            v-for="(pd_filter, i) in dataForm.pd_filters" :key="i"
                            class="w-6/12 odd:pr-2 even:pl-2 pb-4">
                            <n-card
                                size="small"
                                class="overflow-hidden shadow bg-white dark:bg-darkCardBg rounded-md relative"
                                :segmented="{
                                    content: true,
                                    footer: 'soft'
                                }">
                                <n-icon
                                    class="text-red-500 text-2xl ml-4 cursor-pointer absolute top-2 right-2"
                                    @click="onDeletePDFilter(i)">
                                    <DeleteDismiss24Regular />
                                </n-icon>
                                
                                <div class="flex mt-4">
                                    <rb-input
                                        class="w-6/12 pr-1"
                                        :label="tools.localization['tools_volatility_analyzer_pump_dump_filter_period']"
                                        :help="tools.help['switch_pd_period']"
                                        v-model:value="dataForm.pd_filters[i].pd_period" />
                                    <rb-input
                                        class="w-6/12 pl-1"
                                        :label="tools.localization['tools_volatility_analyzer_pump_dump_filter_rate_change_percent']"
                                        :help="tools.help['switch_pd_rate_change']"
                                        v-model:value="dataForm.pd_filters[i].pd_rate_change" />
                                </div>
                            </n-card>
                        </div>

                        <div class="w-6/12 odd:pr-2 even:pl-2 pb-4 min-h-[122px]">
                            <n-button
                                strong
                                secondary
                                class="w-full h-full rounded-md"
                                @click="onAddPDFilter">
                                {{ tools.localization['tools_volatility_analyzer_pump_dump_filters_add_filter_button'] }}
                            </n-button>
                        </div>
                    </section>
                </section>
                
                <template v-if="marketSelected">
                    <div class="flex justify-end items-center my-4">
                        <n-button
                            class="text-white/90 rounded-md"
                            :color="gl.mainColor"
                            @click="onRunPreset">
                            {{ tools.localization['tools_volatility_analyzer_run_analyzer_button'] }}
                        </n-button>
                        <n-checkbox
                            v-if="vaResultsFetched"
                            class="ml-3 text-white/90 rounded-md"
                            :label="tools.localization['tools_volatility_analyzer_save_preset_title']"
                            v-model:checked="continueSavingPreset">
                        </n-checkbox>
                    </div>
                </template>

                <template v-if="continueSavingPreset">
                    <n-divider />
                    <rb-input
                        :label="tools.localization['tools_volatility_analyzer_save_preset_name_f']"
                        :placeholder="tools.localization['tools_volatility_analyzer_save_preset_descr_f']"
                        :status="errorsPresetField.name.status ? 'error' : ''"
                        :msg="errorsPresetField.name.msg"
                        v-model:value="dataForm.title"
                        @update:value="errorsPresetField.name.status = null, errorsPresetField.name.msg = null" />
                    <rb-input
                        class="mt-4"
                        typeField="textarea"
                        :label="tools.localization['tools_volatility_analyzer_save_preset_description_f']"
                        :placeholder="tools.localization['tools_volatility_analyzer_save_preset_description_i']"
                        v-model:value="dataForm.description" />
                    <div class="flex justify-end">
                        <n-button
                            class="mt-4 rounded-md text-white/90"
                            :color="gl.mainColor"
                            @click="onSavePreset">
                            {{ tools.localization['tools_volatility_analyzer_save_preset_save_button'] }}
                        </n-button>
                    </div>
                    <n-divider />
                </template>
            </template>
                
            <super-table
                v-if="vaResultsFetched"
                ref="botTvaResultsBlock"
                :refresh="false"
                :showFooter="false"
                :pager="vaResultsFetched.pager"
                :columns="vaResultsFetched.columns"
                :records="vaResultsFetched.records"
                columnsStorageName="__columns_volatility-analyzer">
            </super-table>
        </template>
    </n-card>
</div>
</template>

<script>
// vue
import { ref, reactive, computed, watch, onMounted, onUnmounted } from 'vue';

// store
import { useGl } from '@/store/gl';
import { useTools } from '@store/tools';
import { useRefs } from '@/store/refs';

// shared
import { VA_SETTINGS_FORM_FACTORY, VA_PD_FILTER_FACTORY, BOTS_DATA_FORM_FACTORY_PREPARE } from '@shared/factories';
import { formatUnexistedCurrsAsUnknown, formatUnexistedCurrsAsUnknownObject } from '@shared/util';

// components
import RbInput from '@components/rb-input';
import RbSelect from '@components/rb-select';
import RbSwitch from '@components/rb-switch';
import RbCoinList from '@components/rb-coin-list';
import RbCurrencies from '@components/rb-currencies';
import SuperTable from '@components/super-table/index.vue';

// i18n
import { useI18n } from 'vue-i18n';

// services
import ExchangesService from '@services/exchangesService';
import ToolsService from '@services/tools';
import fromExponential from 'from-exponential';

// icons
import { Dismiss16Filled, DeleteDismiss24Regular, QuestionCircle20Filled } from '@vicons/fluent';

// UI
import {
    NIcon,
    NCard,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    useNotification } from 'naive-ui';

export default {
    name: 'newTemplate',
    components: {
        NIcon,
        NCard,
        RbInput,
        NButton,
        NDivider,
        RbSelect,
        NPopover,
        RbSwitch,
        NCheckbox,
        SuperTable,
        RbCoinList,
        RbCurrencies,
        Dismiss16Filled,
        DeleteDismiss24Regular,
        QuestionCircle20Filled,
    },
    setup(props, { emit }) {
        // store
        const gl = useGl();
        const tools = useTools();
        const refs = useRefs();

        // ui
        const notification = useNotification();

        // i18n
        const { t } = useI18n();

        // vars
        const { _ } = window;
        const markets = ref();
        const init = ref(false);
        const botTvaResultsBlock = ref();
        const sharedCoinList = ref();
        const vaResultsFetched = ref();
        const sharedCoinListRef = ref();
        const dataForm = reactive({});
        const errorsPresetField = reactive({
            name: {
                status: null,
                msg: null,
            },
        });
        const continueSavingPreset = ref(false);
        const symbolsForMarketRefs = ref();

        const isMarketSelected = computed(() => dataForm.market.value !== -1);
        const isExchangeSelected = computed(() => dataForm.exchange.value !== -1);
        const marketSelected = computed(() => dataForm.market.value && parseInt(dataForm.market.value, 10) !== -1);
        const canShowSharedCoinList = computed(() =>
            ['shared', 'local_and_shared'].includes(dataForm.coin_list_strategy)
            && dataForm.shared_coin_list != -1);
        const exchangeOptions = computed(() => refs.exchanges.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const coinListStrategiesOptions = computed(() => tools.coinListStrategies.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));

        const symbolsForMarketRefsOptions = computed(() => 
            symbolsForMarketRefs.value
                ? Object.values(symbolsForMarketRefs.value).map(el => ({
                    label: el.title,
                    value: el.id,
                    ...el,
                }))
                : []);
        
        const periodUnitsOptions = computed(() => tools.periodUnits.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));
        
        const intervalUnitsOptions = computed(() => tools.intervalUnits.map(el => ({
            label: el.title,
            value: el.id,
            ...el,
        })));
        
        const sharedCoinListItems = computed(() => {
            if (dataForm.exchange.value == -1) return [];

            return tools.coinLists
                .filter(cl => cl.exchange == dataForm.exchange.value)
                .map(el => ({
                    label: el.title,
                    value: el.id,
                    ...el,
                }));
        });
      
        const currencyOptions = computed(() => Array.isArray(markets.value)
            ? formatUnexistedCurrsAsUnknown(refs, markets.value).map(el => ({
                value: el.id,
                label: el.title,
                ...el,
            }))
            : []);

        watch(() => dataForm.exchange?.value, v => {
            if (!tools.currentPreset) {
                dataForm.market.value = -1;
            } else {
                tools.currentPreset = null;
            }
            
            if (v !== -1)
                getMarkets(v);
        });

        watch(() => dataForm.shared_coin_list, v => {
            if (v === null) {
                sharedCoinList.value = null;
            }

            if (v != -1)
                fetchSharedCoinListRef(v);
        });

        watch(() => dataForm.market?.value, async newVal => {
            if (!newVal || newVal == -1) return;

            getSymbolsForMarket();
        });

        const initModule = () => {
            const res = tools.currentPreset || VA_SETTINGS_FORM_FACTORY();

            for (const [key, value] of Object.entries(res)) {
                if (key === 'exchange') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_create_new_preset_exchange_f'],
                        dataHelp: tools.help['switch_exchange'],
                        placeholder: tools.localization['tools/va/coin_list/select_exchange'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        show: true,
                        customField: true,
                    };
                } else if (key === 'market') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_create_new_preset_market_f'],
                        dataHelp: tools.help['switch_market'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                        show: computed(() => isExchangeSelected.value),
                    };
                } else if (key === 'use_bw_list') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_black_white_lists'],
                        dataHelp: tools.help['switch_use_bw_list'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                        show: computed(() => isMarketSelected.value),
                    };
                } else if (key === 'period_value') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_period'],
                        dataHelp: tools.help['switch_period'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                    };
                } else if (key === 'interval_value') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_interval'],
                        dataHelp: tools.help['switch_interval'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                    };
                } else if (key === 'rate_change') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_rate_change_f'],
                        dataHelp: tools.help['switch_rate_change'],
                        placeholder: tools.localization['tools_volatility_analyzer_rate_change_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                    };
                } else if (key === 'min_rate_changes_count') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_min_rate_change_count_f'],
                        dataHelp: tools.help['switch_min_rate_change_count'],
                        placeholder: tools.localization['tools_volatility_analyzer_min_rate_change_count_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                    };
                } else if (key === 'min_trade_volume') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_min_trade_volume_f'],
                        dataHelp: tools.help['switch_min_trade_volume'],
                        placeholder: tools.localization['tools_volatility_analyzer_min_trade_volume_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                    };
                } else if (key === 'min_price') {
                    dataForm[key] = {
                        value,
                        title: tools.localization['tools_volatility_analyzer_min_price_f'],
                        dataHelp: tools.help['switch_min_price'],
                        placeholder: tools.localization['tools_volatility_analyzer_min_price_i'],
                        status: undefined,
                        msg: undefined,
                        loading: false,
                        customField: true,
                    };
                } else if (['period_unit', 'interval_unit'].includes(key)) {
                    dataForm[key] = +value;
                } else dataForm[key] = value;
            };

            init.value = true;
        };

        const getMarkets = async id => {
            gl.showLoading = true;
          
            try {
                const result = ( await ExchangesService.getMarkets({
                    ids: [id],
                }) ).data;

                if (result?.errors) {
                    result.errors.forEach(({ msg }) => notification.error({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    }));
                } else {
                    markets.value = result.markets;
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };

            gl.showLoading = false;
        };

        const getSymbolsForMarket = async () => {
            gl.showLoading = true;

            try {
                var prepare = ( await ExchangesService.getSymbolsForMarket({
                    exchange: dataForm.exchange.value,
                    market: dataForm.market.value,
                }) ).data;

                symbolsForMarketRefs.value = formatUnexistedCurrsAsUnknownObject(refs, prepare.symbols || []);
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }

            gl.showLoading = false;
        };

        const fetchSharedCoinListRef = async id => {
            gl.showLoading = true;

            try {
                var data = await ToolsService.getCoinListInfo(id);

                if (!data?.data.status) {
                    notification.error({
                        content: data.data?.message,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                } else {
                    sharedCoinList.value = data.data.coin_list.coins;
                    sharedCoinListRef.value = formatUnexistedCurrsAsUnknown(refs, data.data.coin_list.coins).map(el => ({
                        value: el.id,
                        label: el.title,
                        ...el,
                    }));
                };
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
            
            gl.showLoading = false;
        };

        const onRunPreset = async () => {
            gl.showLoading = true;
            gl.loadingMessage = tools.localization['tools_volatility_analyzer_run_analyzer_spinner_message'];

            const pd_filters = [...dataForm.pd_filters].map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));
            const prepare = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);

            const payload = {
                ...prepare,
                pd_filters,
                min_price: fromExponential(dataForm.min_price.value),
                _formPath: 'tvaSettings',
                _source: 'tools',
            };

            try {
                var data = await ToolsService.runPreset(payload);

                if (!data.data.status) {
                    if (data.data?.errors_form) {

                        for (let key in dataForm) {
                            const fields = data.data.errors_form.tvaSettings.fields;
                            const el = Object.keys(fields).find(el => el === key);

                            if (typeof dataForm[key] === 'object' && dataForm[key] !== null) {
                                if (el) {
                                    dataForm[key].status = 'error';
                                    dataForm[key].msg = fields[el].msg;
                                } else {
                                    dataForm[key].status = 'success';
                                    dataForm[key].msg = undefined;
                                }
                            }
                        }
                    }
                } else {
                    // show messages
                    data.postMessages.forEach(el => {
                        notification[el.success ? 'success' : 'error']({
                            content: el.msg,
                            duration: 2500,
                            keepAliveOnHover: true,
                        });
                    });

                    vaResultsFetched.value = data.data;

                    setTimeout(() => {
                        botTvaResultsBlock.value.$el.scrollIntoView({ block: 'start', behavior: 'smooth' });
                    });
                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }
            
            gl.loadingMessage = '';
            gl.showLoading = false;
        };

        const getRefs = async (showLoading = true) => {
            if (showLoading)
                gl.showLoading = true;

            try {
                tools.refs = ( await ToolsService.getRefs() ).data;
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            };
            
            if (showLoading)
                gl.showLoading = false;
        };

        const onSavePreset = async () => {
            gl.showLoading = true;

            const pd_filters = [...dataForm.pd_filters].map((filter, i) => ({_formPath: `pd_fitler_${i}`, ...filter}));
            const prepare = BOTS_DATA_FORM_FACTORY_PREPARE(dataForm);

            const payload = {
                ...prepare,
                pd_filters,
                min_price: fromExponential(dataForm.min_price.value),
                _formPath: 'tvaSettings',
                _source: 'tools',
            };

            try {
                var data = await ToolsService.savePreset(payload);

                if (!data?.data.status) {
                    for (const [key, value] of Object.entries(data.data.errors_form)) {
                        errorsPresetField[key] = {
                            ...value,
                            status: 'error',
                        };
                    }
                } else {
                    const { id, title, message: msg } = data.data;
                    notification.success({
                        content: msg,
                        duration: 2500,
                        keepAliveOnHover: true,
                    });
                    tools.currentPresetId = id;
                    await getRefs();
                    
                    emit('chacngeTab', 'rbpsPresets');

                }
            } catch {
                notification.error({
                    content: t('errorMessage'),
                    duration: 2500,
                    keepAliveOnHover: true,
                });
            }

            gl.showLoading = false;
        };

        const onDeletePDFilter = i => {
            dataForm.pd_filters.splice(i, 1);
        };

        const onAddPDFilter = () => {
            dataForm.pd_filters.push(VA_PD_FILTER_FACTORY());
        };

        const currency = icon => refs.currencies.find(el => el.id === icon);

        onMounted(() => {
            initModule();
        });

        onUnmounted(() => {
            // tools.currentPreset = null;
        });

        return {
            gl,
            init,
            tools,
            markets,
            dataForm,
            marketSelected,
            sharedCoinList,
            exchangeOptions,
            currencyOptions,
            isMarketSelected,
            errorsPresetField,
            vaResultsFetched,
            botTvaResultsBlock,
            sharedCoinListRef,
            periodUnitsOptions,
            sharedCoinListItems,
            continueSavingPreset,
            intervalUnitsOptions,
            canShowSharedCoinList,
            coinListStrategiesOptions,
            symbolsForMarketRefsOptions,
            onRunPreset,
            onSavePreset,
            onAddPDFilter,
            onDeletePDFilter,
        };
    },
};
</script>