<template>
<section class="text-left">
    <div v-if="title" class="font-semibold text-gray-600 dark:text-white/75 whitespace-nowrap mt-4">{{ title }}</div>
    
    <div class="flex flex-wrap justify-between mt-6">
        <div class="w-[calc(25%-8px)] mb-3">
            <n-button
                type="error"
                size="medium"
                class="w-full rounded-md"
                @click="$emit('presetChanged', null)">
                {{ t('resetSettings') }}
            </n-button>
            <div class="mt-2 text-xs text-left">{{ t('resetSettingsMessage') }}</div>
        </div>
        <div
            v-for="preset in presets"
            :key="preset.id"
            class="w-[calc(25%-8px)] mb-3">
            <n-button
                secondary
                size="medium"
                class="w-full rounded-md overflow-x-auto"
                :class="preset.id == currPresetId ? 'bg-main text-white/90' : null"
                :strong="preset.id == currPresetId"
                @click="$emit('presetChanged', preset.id)">
                {{ preset.title }}
            </n-button>
            <div class="mt-2 text-xs text-left">{{ preset.description }}</div>
        </div>
    </div>
    <n-divider />

    <div class="flex flex-wrap justify-between mt-6">
        <div
            v-for="preset in secondPresets"
            :key="preset.id"
            class="w-[calc(33%-8px)] mb-3">
            <n-button
                :ghost="preset.id != currPresetId"
                :strong="preset.id == currPresetId"
                :color="gl.mainColor"
                class="w-full rounded-md"
                @click="$emit('presetChanged', preset.id)">
                {{ preset.title }}
            </n-button>
            <div class="mt-2">{{ preset.description }}</div>
        </div>
    </div>
    <slot></slot>
</section>
</template>

<script>
// store
import { useGl } from '@/store/gl';
import { useBots } from '@/store/bots';

// i18n
import { useI18n } from 'vue-i18n';

// naive-ui
import {
    NIcon,
    NCard,
    NAlert,
    NInput,
    NSelect,
    NButton,
    NDivider,
    NPopover,
    NCheckbox,
    useMessage } from 'naive-ui';

export default {
    name: 'rb-presets',
    emits: ['presetChanged'],
    props: {
        title: {
            type: String,
        },
        presets: {
            type: Array,
            required: true,
        },
        secondPresets: {
            type: Array,
        },
        currPresetId: {
            type: [ String, Number ],
        },
    },
    components: {
        NCard,
        NButton,
        NDivider,
    },
    setup() {
        // store
        const gl = useGl();
        
        // i18n
        const { t } = useI18n();

        return {
            gl,
            t,
        };
    },
};
</script>